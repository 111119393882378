import { Ringba } from './Ringba.js'
import { HookRunner } from './HookRunner.js'
import { Hook } from './enums/HookEnum.js'
import { Action } from './enums/ActionEnum.js'
import { AudioManager } from './AudioManager.js'
import { Chat } from './Chat.js'
import { StepHistoryStore } from './StepHistoryStore.js'
import { MediaAlpha } from './MediaAlpha.js'
import { Navigation } from './Navigation.js'
import { Helpers } from './Helpers.js'
import SmartySDK from 'smartystreets-javascript-sdk'
import { Alpine, Livewire } from '../dist/livewire/livewire.esm.js'
import AlpineSmartyAddress from './AlpineSmartyAddress.js'
import { livewire_hot_reload as livewireHotReload } from 'virtual:livewire-hot-reload'
import QvMask from './alpine/directives/mask'
import QvRouter from './alpine/plugins/router'
import { QuinnStreet } from './QuinnStreet'

const SmartyCore = SmartySDK.core
const Lookup = SmartySDK.usAutocompletePro.Lookup
const key = import.meta.env.VITE_SMARTY_LICENSE
const credentials = new SmartyCore.SharedCredentials(key)
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([ 'us-autocomplete-pro-cloud' ])
const client = clientBuilder.buildUsAutocompleteProClient()

Alpine.plugin(QvRouter)
Alpine.plugin(QvMask)
Alpine.data('smartyAddress', AlpineSmartyAddress)

Alpine.store('qv', {
    helpers: new Helpers(),
    navigation: new Navigation(),
    ringba: new Ringba(),
    hookRunner: new HookRunner(),
    mediaAlpha: new MediaAlpha(),
    metadata: window.__METADATA ?? {},
    modals: {},
    quinnStreet: new QuinnStreet(),
    smartyClient: client,
    smartyLookup: Lookup,
    audioManager: new AudioManager(),
    stepHistory: new StepHistoryStore(),
    chat: new Chat(),
    webForm: {
        canSubmit: true,
    },

    /**
     * @param {string} url
     * @param {string} offset
     * @returns {void}
     */
    playWebFormAudio(url, offset) {

        if (this.stepHistory.hasAnsweredStep(offset)) {
            return
        }

        this.audioManager.autoplayStepAudio(url)

    },

    runBeforeStepSubmissionHook(formData) {
        this.hookRunner.run(Hook.BeforeStepSubmission, formData, this.metadata)
    },

    runAfterEnterThankYouPageHook(metadata) {
        this.hookRunner.run(Hook.AfterEnterThankYouPage, metadata)
    },

    bindModalHandlers() {

        const modalLinks = Array.from(document.getElementsByClassName('modal-link'))

        for (const element of modalLinks) {

            element.removeEventListener('click', event => this.processModalEvent(event))
            element.addEventListener('click', event => this.processModalEvent(event))

        }

    },

    processModalEvent(event) {

        if (event.target instanceof HTMLElement) {

            const id = event.target.getAttribute('data-modal')

            if (id && Object.keys(this.modals).includes(id)) {

                Livewire.dispatch('openModal', {
                    component: 'modals.base-modal',
                    arguments: { content: this.modals[ id ] },
                })

            }

        }

    },

    setModals(modals) {
        this.modals = { ...modals }
    },

    handleAction(action, ringbaPhoneNumber, callback = null) {

        switch (action?.type) {

        case Action.InternalLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ThunderboltLink:
            window.location.href = action.url
            callback?.call()

            return

        case Action.ExternalLink:
            window.open(action.url, '_blank')
            callback?.call()

            return

        case Action.TriggerDialer:
            window.open(`tel:${ action.phoneNumber || ringbaPhoneNumber.number }`, '_self')
            callback?.call()

            return

        case Action.StartWebForm: {

            if (this.navigation.startWebForm()) {
                callback?.call()
            }

        }

        }

        return null

    },

})

livewireHotReload()
Livewire.start()
